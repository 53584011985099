<template>
  <CommonLoading v-if="loading" />
  <form
    v-else
    id="form_trial_signup_callme"
    class="w-64 ml-auto mr-auto text-center grid gap-4"
    :class="{ 'w-fit h-full': isMm }"
    @submit.prevent="submit"
  >
    <p v-if="isMm" class="mx-auto text-sm text-gray-800 leading-snug mb-4">
      Vi ringer til dig med et uforpligtende tilbud på Mandag Morgen. Det står
      dig altså frit for, om du vil acceptere tilbuddet.
    </p>
    <p v-else class="mx-auto text-sm text-gray-800 leading-snug mb-4">
      {{ $t('trialSignup.callMeFormInfo') }}
    </p>

    <FormAlert :type="alertType" :message="alertMessage" />

    <FormInput
      ref="nameinput"
      v-model="name"
      required
      placeholder="NameHelpText"
      label="Name"
    />

    <FormInput
      ref="emailinput"
      v-model="email"
      type="email"
      required
      placeholder="Email"
      label="Email"
    />

    <FormInput
      ref="phoneinput"
      v-model="phone"
      type="tel"
      required
      placeholder="PhoneShort"
      :pattern="config.public.site.formValidation?.phonePattern"
      label="Phone"
    />
    <FormInput
      v-if="isMm"
      ref="titleinput"
      v-model="title"
      type="text"
      required
      placeholder="Stilling"
      label="Stilling"
    />
    <FormInput
      v-if="isMm"
      ref="companyinput"
      v-model="company"
      type="text"
      required
      placeholder="Virksomhed"
      label="Virksomhed"
    />

    <div v-if="isMm" class="flex items-start justify-start">
      <label class="flex">
        <FormCheckbox ref="checkbox" @input="updateConsent" />
        <i18n-t
          keypath="newsletterConsent.text"
          tag="div"
          scope="global"
          class="body-xs text-gray-700 text-left"
        >
          <template #siteName>
            <span>{{ config.public.site.name }}</span>
          </template>
          <template #alike>
            <CommonTooltip :text="$t('newsletterConsent.alike.more')">
              <span class="underline">{{
                $t('newsletterConsent.alike.text')
              }}</span>
            </CommonTooltip>
          </template>
          <template #group>
            <CommonTooltip :text="$t('newsletterConsent.group.more')">
              <span class="underline">{{
                $t('newsletterConsent.group.text')
              }}</span>
            </CommonTooltip>
          </template>
          <template #privacyPolicy>
            <NuxtLink
              :to="{
                name: 'article',
                params: {
                  id: config.public.site.cookiePolicy,
                },
              }"
              class="underline hover:text-blue"
            >
              <span>{{ $t('newsletterConsent.privacyPolicy.text') }}</span>
            </NuxtLink>
          </template>
        </i18n-t>
      </label>
    </div>
    <input
      type="submit"
      class="w-full cursor-pointer py-3 px-4 text-white article-label !font-medium"
      :value="isMm ? 'Ring mig op' : $t('Next')"
      :class="
        isMm
          ? 'rounded-md bg-black transform translate-y-px'
          : 'rounded-full bg-blue hover:bg-denimblue'
      "
      @click="checkConsent"
    />

    <p class="mx-auto text-sm leading-5 text-gray-700">
      {{ $t('trialSignup.callMeFormDetails') }}
    </p>
  </form>
</template>

<script setup lang="ts">
const name = ref('')
const email = ref('')
const phone = ref('')
const title = ref('')
const company = ref('')
let loading = ref(false)

const emit = defineEmits(['complete', 'signed-up'])
const { isMm } = useBrand()
const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()
const route = useRoute()
const gtm = useGtm()

const props = withDefaults(
  defineProps<{
    eventLabel?: string
    newsletter?: string | number
  }>(),
  {
    eventLabel: 'FreeNewsletterSignup',
    newsletter: 1, // 1 = the free frontpage newsletter.
  }
)

async function submit() {
  loading.value = true
  clearAlert()
  try {
    await nuxtApp.$api.user.trialSignup({
      email: email.value,
      name: name.value,
      phone: phone.value,
      url: route.fullPath,
      title: title.value,
      jobPlace: company.value,
    })
    emit('complete')
  } catch (error) {
    setAlert(error?.response?.data)
  }
  loading.value = false

  if (!consentGiven.value) {
    return
  } else {
    await nuxtApp.$api.user
      .subscribeFreeNewsletter(email.value, FREE_NEWSLETTER_ID)
      .then(() => {
        emit('signed-up')

        gtm?.trackEvent({
          event: 'formTracking',
          eventCategory: 'Form',
          eventAction: 'Form Completed',
          eventLabel: props.eventLabel,
        })
        nwCookie.value = true
      })
  }
}

// Error handling
const { setAlert, clearAlert, alertType, alertMessage } = useAlert()

// Newsletter consent
const consentGiven = ref(false)
const { nwCookie } = useNwCookie()

function checkConsent() {
  !consentGiven.value
}
function updateConsent(newValue: boolean) {
  consentGiven.value = newValue
}
</script>
